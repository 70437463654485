




































import { Component, Mixins, Watch } from 'vue-property-decorator';

import Heading from '@/components/checkout/layout/page/Heading.vue';
import Button from '@/components/ui/Button.vue';
import TextField from '@/components/ui/form/TextField.vue';
import { ChevronLeftIcon } from 'vue-feather-icons';

import { HomeRoute, LoginRoute, ReservationRoute } from '@/router/routes';
import PageLayout from '@/components/checkout/PageLayout.vue';
import { minLength, required } from 'vuelidate/lib/validators';
import UserService from '@/services/user.service';
import { createVanSelectionRoute } from '@/utils/routing';
import { AuthMixin } from '@/mixins/user/Auth.mixin';

@Component({
  components: {
    PageLayout,
    Button,
    TextField,
    ChevronLeftIcon,
    Heading
  },
  validations: {
    form: {
      password: {
        // todo dat nekam do komponenty
        required,
        minLength: minLength(8),
        isValid(value) {
          return (
            /^[A-Za-z0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]*$/.test(value) &&
            /[a-z]/.test(value) &&
            /[A-Z]/.test(value) &&
            /\d/.test(value)
          );
        }
      }
    }
  }
})
export default class ResetPassword extends Mixins(AuthMixin) {
  private form = {
    password: ''
  };

  get queryFrom(): string {
    return this.$route.query.from?.toString() ?? '';
  }

  get queryTo(): string {
    return this.$route.query.to?.toString() ?? '';
  }

  get car(): string {
    return this.$route.query.carId?.toString() ?? '';
  }

  get resetPasswordToken(): string {
    return this.$route.params.resetPasswordToken.toString() ?? '';
  }

  validate() {
    this.$v.form.$touch();
    return !this.$v.form.$invalid;
  }

  validationMessage(field: string): string | null {
    const validation = this.$v.form[field];
    if (!validation || !validation.$error) {
      return null;
    }

    switch (field) {
      case 'password':
        if (!validation.required) {
          return 'Zadejte heslo';
        }
        if (!validation.minLength) {
          return 'Heslo musí mít minimálně 8 znaků';
        }
        return 'Heslo musí obsahovat velké písmeno, číslici a speciální znak';
    }

    return null;
  }

  async mounted() {
    if (this.loggedUser !== undefined || this.resetPasswordToken.length === 0) {
      await this.$router.push({
        name: HomeRoute
      });
    }
    try {
      await UserService.checkResetPasswordToken(this.resetPasswordToken);
    } catch (e) {
      this.$toast.error(`Verifikační token není správný`);
      await this.$router.push({
        name: HomeRoute
      });
    }
  }

  async setPassword() {
    try {
      if (!this.validate()) {
        return;
      }
      await UserService.resetPassword(this.resetPasswordToken, this.form.password);
      this.$toast.success('Heslo bylo změněno');
      if (this.car) {
        await this.$router.push({
          query: {
            from: this.queryFrom,
            to: this.queryTo,
            car: this.car,
            login: '1'
          },
          name: ReservationRoute
        });
      } else {
        await this.$router.push({
          query: {
            login: '1'
          },
          name: LoginRoute
        });
      }
    } catch (err) {
      const e = err as Error;
      this.$toast.error(`Nastala chyba při změně hesla: ${e.message}`);
    }
  }

  @Watch('loggedUser')
  async onLoggedUserChange() {
    this.$toast.error(`Nelze měnit heslo u přihlášeného uživatele`);
    await this.$router.push({
      name: HomeRoute
    });
  }

  goBackToVans() {
    this.$router.push({
      query: {
        from: this.queryFrom,
        to: this.queryTo
      },
      name: createVanSelectionRoute()
    });
  }
}
